<template>
  <SelectBase v-model="ids"
              :list="data"
              :options="{id: 'id', name: 'title'}"
              :mode="'multiple'"
              placeholder="请选择线上任务"
              @change="$emit('change', ids)"/>
</template>

<script>
import { mapState } from 'vuex';
import SelectBase from './SelectBase.vue';

export default {
  name: 'SelectOnlineTask',
  components: {
    SelectBase,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String, default: '' },
  },
  computed: {
    ...mapState({
      data: state => state.Common.onlineTaskList || [],
    }),
  },
  watch: {
    value(val) {
      this.ids = val || '';
    },
  },
  data() {
    return {
      ids: '',
    };
  },
  created() {
    this.ids = this.value || '';
    if (!this.data?.length) {
      this.$store.dispatch('Common/getOnlineTaskList', { title: '', page: 1, per_page: 9999 });
    }
  },
};
</script>

<style scoped lang="scss">

</style>
