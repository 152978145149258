<template>
  <a-form-model layout="inline"
                :model="form">
    <a-form-model-item :label="'组织机构'">
      <app-select-org v-model="form.org_id_str" :show-select-all-org="true"/>
    </a-form-model-item>
    <a-form-model-item :label="'店型'">
      <app-select-shop-type :value="form.shopType" @change="selectShopType"/>
    </a-form-model-item>
    <a-form-model-item :label="'证书岗位'">
      <app-select-job v-model="form.cert_post_id_str" :list="certificateJobList"/>
    </a-form-model-item>
    <a-form-model-item :label="'姓名/电话/身份证号'">
      <a-input v-model="form.truename_or_username_or_idcard"
               :allow-clear="true"
               placeholder="请输入姓名/电话/身份证号"
               class="filter-text"/>
    </a-form-model-item>
    <a-form-model-item :label="'发证时间'">
      <a-range-picker v-model="form.date"/>
    </a-form-model-item>
    <a-form-model-item :label="'当前人员岗位'">
      <app-select-job v-model="form.post_id_str"/>
    </a-form-model-item>
    <a-form-model-item :label="'证书编号'">
      <a-input v-model="form.certificate_number"
               :allow-clear="true"
               placeholder="请输入证书编号"
               class="filter-text"/>
    </a-form-model-item>
    <a-form-model-item :label="isOffline ? '关联培训' : '关联任务'">
      <SelectOfflineTask v-if="isOffline" v-model="form.offline_ids"/>
      <SelectOnlineTask v-else v-model="form.online_ids"/>
    </a-form-model-item>

    <a-form-item class="full-width flex flex-end">
      <a-button type="primary" :loading="loading" @click="onSearch">查询</a-button>
      <a-button type="primary" @click="onExport">下载证书</a-button>
      <a-button class="mr-30" @click="onReset">重置</a-button>
      <a-button type="primary" @click="onExportExcel">导出数据</a-button>
    </a-form-item>
  </a-form-model>
</template>

<script>
import { getCertificateJobList } from './api';

import SelectOfflineTask from '@/components/select/SelectOfflineTask.vue';
import SelectOnlineTask from '@/components/select/SelectOnlineTask.vue';

export default {
  name: 'Query',
  props: {
    loading: { type: Boolean, default: false },
    downloading: { type: Boolean, default: false },
  },
  components: {
    SelectOfflineTask,
    SelectOnlineTask,
  },
  computed: {
    isOffline() {
      return [
        '/index/certificate/overview/offline',
        '/index/statistics/certificate/offline',
      ].includes(this.$route.path);
    },
  },
  data() {
    return {
      certificateJobList: [],

      form: {
        truename_or_username_or_idcard: '',
        post_id_str: '',
        org_id_str: '',
        rel_id_str: '',
        date: [undefined, undefined],
        offline_ids: '',
        online_ids: '',
        cert_post_id_str: '',
        certificate_number: '',

        shopType: { store_state: 1 },
      },
    };
  },
  created() {
    this.getCertificateJobList();
  },
  methods: {
    async getCertificateJobList() {
      const data = await getCertificateJobList();
      this.certificateJobList = data?.data || [];
    },

    onSearch() {
      this.$emit('search', this.getParams());
    },
    onReset() {
      this.form = {
        truename_or_username_or_idcard: '',
        post_id_str: '',
        org_id_str: '',
        rel_id_str: '',
        date: [undefined, undefined],
        offline_ids: '',
        online_ids: '',
        cert_post_id_str: '',
        certificate_number: '',
        shopType: { store_state: 1 },
      };
      this.$emit('reset', {
        truename_or_username_or_idcard: '',
        post_id_str: '',
        org_id_str: '',
        publish_start_day: '',
        publish_end_day: '',
        rel_id_str: '',
        cert_post_id_str: '',
        certificate_number: '',
        shopType: { store_state: 1 },
      });
    },
    onExport() {
      this.$emit('export', {
        ...this.getParams(),
      });
    },
    onExportExcel() {
      this.$emit('export-excel', {
        ...this.getParams(),
      });
    },

    getParams() {
      const params = { ...this.form };
      params.publish_start_day = params.date[0]?.format('YYYY-MM-DD') || '';
      params.publish_end_day = params.date[1]?.format('YYYY-MM-DD') || '';
      params.rel_id_str = this.isOffline ? params.offline_ids : params.online_ids;
      if (params.shopType) {
        params.store_state = params.shopType.store_state || '';
        params.store_type = params.shopType.store_type || '';
        params.operation_state = params.shopType.operation_state || '';
        params.store_age = params.shopType.store_age || '';
      }
      delete params.date;
      delete params.offline_ids;
      delete params.online_ids;

      return params;
    },

    selectShopType(event) {
      this.form.shopType = event;
    },
  },
};
</script>

<style scoped lang="scss">
.filter-text {
  min-width: 260px;
}
</style>
